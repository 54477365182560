import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-control-sidebar',
  templateUrl: './control-sidebar.component.html',
  styleUrls: ['./control-sidebar.component.scss']
})
export class ControlSidebarComponent implements OnInit, AfterViewInit {
  tamplate_setting: any;

  navbar_dark_skins = ['primary', 'secondary', 'info', 'success', 'danger', 'indigo', 'purple', 'pink', 'teal', 'cyan', 'dark', 'gray-dark', 'gray']
  navbar_light_skins = ['light', 'warning', 'white', 'orange']
  accent_colors = ['primary', 'warning', 'info', 'danger', 'success', 'indigo', 'navy', 'purple', 'fuchsia', 'pink', 'maroon', 'orange', 'lime', 'teal', 'olive']
  sidebar_dark_skins = ['primary', 'warning', 'info', 'danger', 'success', 'indigo', 'navy', 'purple', 'fuchsia', 'pink', 'maroon', 'orange', 'lime', 'teal', 'olive']
  sidebar_light_skins = ['primary', 'warning', 'info', 'danger', 'success', 'indigo', 'navy', 'purple', 'fuchsia', 'pink', 'maroon', 'orange', 'lime', 'teal', 'olive']

  constructor(    
    public el: ElementRef
  ) {
    this.tamplate_setting = JSON.parse(localStorage.getItem('tamplate_setting'));
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.getTamplateSetting();
  }

  getTamplateSetting() {
    this.tamplate_setting.no_navbar_border ? 
    document.querySelector(".main-header").classList.add('border-bottom-0') : 
    document.querySelector(".main-header").classList.remove('border-bottom-0');

    this.tamplate_setting.body_small_text ? 
    document.querySelector("body").classList.add('text-sm') : 
    document.querySelector("body").classList.remove('text-sm');

    this.tamplate_setting.navbar_small_text ? 
    document.querySelector("nav.main-header").classList.add('text-sm') : 
    document.querySelector("nav.main-header").classList.remove('text-sm');

    this.tamplate_setting.sidebar_nav_small_text ? 
    document.querySelectorAll("ul.nav").forEach(el=> {el.classList.add('text-sm')}) : 
    document.querySelectorAll("ul.nav").forEach(el=> {el.classList.remove('text-sm')});

    this.tamplate_setting.footer_small_text ? 
    document.querySelector("footer").classList.add('text-sm') : 
    document.querySelector("footer").classList.remove('text-sm');

    this.tamplate_setting.sidebar_nav_flat_style ? 
    document.querySelectorAll("ul.nav").forEach(el=> {el.classList.add('nav-flat')}) : 
    document.querySelectorAll("ul.nav").forEach(el=> {el.classList.remove('nav-flat')});

    this.tamplate_setting.sidebar_nav_legacy_style ? 
    document.querySelectorAll("ul.nav").forEach(el=> {el.classList.add('nav-legacy')}) : 
    document.querySelectorAll("ul.nav").forEach(el=> {el.classList.remove('nav-legacy')});

    this.tamplate_setting.sidebar_nav_compact ? 
    document.querySelectorAll("ul.nav").forEach(el=> {el.classList.add('nav-compact')}) : 
    document.querySelectorAll("ul.nav").forEach(el=> {el.classList.remove('nav-compact')});

    this.tamplate_setting.sidebar_nav_child_indent ? 
    document.querySelectorAll("ul.nav").forEach(el=> {el.classList.add('nav-child-indent')}) : 
    document.querySelectorAll("ul.nav").forEach(el=> {el.classList.remove('nav-child-indent')});

    this.tamplate_setting.main_sidebar_disable_hover ? 
    document.querySelector("aside.main-sidebar").classList.add('sidebar-no-expand') : 
    document.querySelector("aside.main-sidebar").classList.remove('sidebar-no-expand');

    this.tamplate_setting.brand_small_text ? 
    document.querySelector("a.brand-link").classList.add('text-sm') : 
    document.querySelector("a.brand-link").classList.remove('text-sm');
  }

  changeSetting() {
    localStorage.setItem('tamplate_setting', JSON.stringify(this.tamplate_setting));
    this.getTamplateSetting();
    window.location.reload();
  }


}

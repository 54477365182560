import {routerReducer} from '@ngrx/router-store';
import {ActionReducerMap} from '@ngrx/store';
import {IAppState} from '../states/app.state';
import * as fromUser from './user.reducers';
import * as fromRestaurant from './restaurant.reducers';
import * as schedule from './schedule.reducers';


export const reducers: ActionReducerMap<IAppState, any> = {
  router: routerReducer,
  restaurant: fromRestaurant.restaurantReducer,
  user: fromUser.userReducer,
  schedule: schedule.scheduleReducer
};

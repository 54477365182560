import {SystemUser} from '../models/models';

export interface IUserState {
  data: SystemUser;
  token: String;
  is_logged: Boolean;
}

export const inititalUserState: IUserState = {
    data: null,
    token: null,
    is_logged: false
  };

import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  tamplate_setting: any;
  tamplate_setting_data: any;

  constructor(
    public renderer: Renderer2
  ) {
    this.tamplate_setting = JSON.parse(localStorage.getItem('tamplate_setting'));
    this.tamplate_setting_data = {
      "no_navbar_border": false,
      "body_small_text": false,
      "navbar_small_text": false,
      "sidebar_nav_small_text": false,
      "footer_small_text": false,
      "sidebar_nav_flat_style": false,
      "sidebar_nav_legacy_style": false,
      "sidebar_nav_compact": false,
      "sidebar_nav_child_indent": false,
      "main_sidebar_disable_hover": false,
      "brand_small_text": false,
      "navbar_variants": "navbar-dark navbar-danger",
      "accent_color_variants": "accent-primary",
      "sidebar_variants": "sidebar-light-danger",
      "brand_logo_variants": "navbar-danger",
      "sidebar_collapse": false
    }
  }

  ngOnInit() {
    if(this.tamplate_setting === null) {
      localStorage.setItem("tamplate_setting", JSON.stringify(this.tamplate_setting_data));
    }
    // For main tamplate layout
    this.renderer.removeClass(document.body, 'login-page');
    this.renderer.addClass(document.body, 'sidebar-mini');
    this.renderer.addClass(document.body, 'layout-fixed');
    this.renderer.addClass(document.body, 'layout-navbar-fixed');
  }

  hideCollapseMenu() {
    this.renderer.removeClass(document.body, 'sidebar-open');
  }

}

import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers/index';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
//modules
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
//components
import { AppComponent } from './app.component';
import { MainComponent } from './layouts/main/main.component';
import { LoginComponent } from './layouts/login/login.component';
import { HeaderComponent } from './layouts/main/header/header.component';
import { FooterComponent } from './layouts/main/footer/footer.component';
import { MainSidebarComponent } from './layouts/main/main-sidebar/main-sidebar.component';
import { ControlSidebarComponent } from './layouts/main/control-sidebar/control-sidebar.component';
import { SortableModule } from 'ngx-bootstrap/sortable';
// import { RestaurantReportDetailsComponent } from './pages/adminPanel/billing/restaurant-report-details/restaurant-report-details.component';

@NgModule({
  declarations: [
    AppComponent, MainComponent, LoginComponent,
    HeaderComponent, FooterComponent, MainSidebarComponent, ControlSidebarComponent,
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule, AppRoutingModule,
    StoreModule.forRoot(reducers), StoreDevtoolsModule.instrument(),
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    SortableModule.forRoot()
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

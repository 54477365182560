import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromAppStates from '../store/states/app.state';
import {SAVE_USER} from '../store/reducers/user.reducers';
import {SET_SELECTED_RESTAURANT} from '../store/reducers/restaurant.reducers';
import {SET_SCHEDULE} from '../store/reducers/schedule.reducers';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  public logged_user$ = this.db.select(state => state.user);
  public selected_restaurant$ = this.db.select(state => state.restaurant);
  public schedule$ = this.db.select(state => state.schedule);

  constructor(private db: Store<fromAppStates.IAppState>) {
  }

  public setLoginData(data) {
    this.db.dispatch({type: SAVE_USER, payload: data});
  }

  public setRestaurant(data) {
    this.db.dispatch({type: SET_SELECTED_RESTAURANT, payload: data});
  }

  public setSchedule(data) {
    this.db.dispatch({type: SET_SCHEDULE, payload: data});
  }
}

import {Component, OnInit, Renderer2} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
//services
import {HttpService} from 'src/app/services/http.service';
import {RestaurantService} from 'src/app/services/restaurant.service';
import {StoreService} from 'src/app/services/store.service';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {SharedService} from 'src/app/services/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  user: any;
  msg: string = '';
  public emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  submitted = false;

  loading = Swal.mixin({
    allowOutsideClick: false, allowEscapeKey: false
  });

  constructor(private renderer: Renderer2,
              private fb: FormBuilder,
              private router: Router,
              private storeService: StoreService,
              private restaurantService: RestaurantService,
              private authService: AuthenticationService) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.maxLength(60), Validators.pattern(this.emailRegex)]],
      password: ['', [Validators.required, Validators.maxLength(30), Validators.minLength(6)]]
    });
  }

  ngOnInit() {
    this.renderer.removeClass(document.body, 'sidebar-mini');
    this.renderer.removeClass(document.body, 'layout-fixed');
    this.renderer.removeClass(document.body, 'layout-navbar-fixed');
    this.renderer.addClass(document.body, 'login-page');
    this.renderer.removeStyle(document.body, 'height');
    this.user = this.storeService.logged_user$;
  }

  loginAction() {
    this.submitted = true;
    if (this.form.status === 'INVALID') {
      this.msg = 'Please enter required field valid data.'
      return;
    } else {
      this.loading.fire({
        customClass: {container: 'progress-swal'},
        title: 'Please Wait...',
        onOpen: () => {
          Swal.showLoading();
        }
      });
      this.authService.login(this.form.value.email, this.form.value.password).subscribe(result => {
        if (this.loading.isVisible()) {
          this.loading.close();
        }
        if (result['status'] === 'success') {
          const user_data = {data: result.data, token: result.token, is_logged: true};
          this.storeService.setLoginData(user_data);
          if (result.data.user_role === 0) {
            this.router.navigate(['admin/dashboard']).then(() => {
              window.location.reload();
            });
          } else if (result.data.user_role === 1) {
            this.setSelectedRestaurant(result.data);
          }
        } else if (result['status'] === 'error') {
          this.msg = result['msg'];
        }
      }, err => {
        this.loading.fire({
          icon: 'error', title: 'Oops...', text: 'Something went wrong!',
          onOpen: () => {
            Swal.hideLoading();
          }
        });
      });
    }
  }

  setSelectedRestaurant(data) {
    this.loading.fire({
      customClass: {container: 'progress-swal'},
      title: 'Please Wait...',
      onOpen: () => {
        Swal.showLoading();
      }
    });
    this.restaurantService.getOneRestaurant(data.restaurant_id).subscribe(result => {
      if (this.loading.isVisible()) {
        this.loading.close();
      }
      this.storeService.setRestaurant(result);
      sessionStorage.setItem('restaurant_data', JSON.stringify(result));
      sessionStorage.setItem('restaurant_id', data.restaurant_id);
      this.router.navigate(['restaurant/dashboard']).then(() => {
        window.location.reload();
      });
    }, err => {
      this.loading.fire({
        icon: 'error', title: 'Oops...', text: 'Something went wrong!',
        onOpen: () => {
          Swal.hideLoading();
        }
      });
    });
  }

  get f() {
    return this.form.controls;
  }

}

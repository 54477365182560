import { Component, OnInit } from '@angular/core';
import {SharedService} from 'src/app/services/shared.service';
import { RestaurantService } from '../../../services/restaurant.service';

@Component({
  selector: 'app-main-sidebar',
  templateUrl: './main-sidebar.component.html',
  styleUrls: ['./main-sidebar.component.scss']
})
export class MainSidebarComponent implements OnInit  {
  totalOrders: any;
  restaurant: any;
  restaurant_id: any;
  user: any;
  tamplate_setting: any;

  constructor(
    public shared: SharedService,
    private restService: RestaurantService    
  ) {    
    this.restaurant = JSON.parse(sessionStorage.getItem('restaurant_data'));
    this.restaurant_id = sessionStorage.getItem('restaurant_id');
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  ngOnInit() {
    this.tamplate_setting = JSON.parse(localStorage.getItem('tamplate_setting'));
    this.shared.totalOrders.subscribe(item => {
      this.totalOrders = item;
    });
  }

  syncRestaurant() {
    this.shared.loadingStart();
    this.restService.resDataSyncByResId(this.restaurant_id).subscribe(res => {
      this.shared.loadingClose();
    }, err => {
      this.shared.loadingClose();
    })
  }
   
  goToWebLink(data) {
    var url = String(data).replace(/^(https?:\/\/)?(www\.)?/, 'http://');
    window.open(url, '_blank',);
  }

}

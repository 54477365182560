import {Injectable, EventEmitter} from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public totalOrders = new EventEmitter<number>();
  public progress_percent = new EventEmitter<number>();
  public indeterminate_progress = new EventEmitter<number>();
  private RESTAURANT_ID;
  private token: any;

  // for custom loading
  public swalMixin = Swal.mixin({
    allowOutsideClick: false,
    allowEscapeKey: false
  });

  constructor() {
    this.RESTAURANT_ID = sessionStorage.getItem('restaurant_id');
    this.token = sessionStorage.getItem('token');
    this.progress_percent.emit(0);
  }

  setProgress(d) {
    this.progress_percent.emit(d);
  }

  setIndeterminateProgress(isShown) {
    this.indeterminate_progress.emit(isShown);
  }

  setTotalOrders(d) {
    this.totalOrders.emit(d);
  }

  uploadFile(file, folder) {
    let target_directory = '';
    if (folder !== '') {
      target_directory = folder + '/' + file.name;
    } else {
      target_directory = file.name;
    }

    this.RESTAURANT_ID = sessionStorage.getItem('restaurant_id');
    this.token = sessionStorage.getItem('token');
    return new Promise((resolve, reject) => {
      const contentType = file.type;
      const bucket = new S3(
        {
          accessKeyId: 'AKIAVL76YOPFK4NUB5O7',
          secretAccessKey: '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',
          region: 'eu-west-2'
        }
      );
      const params = {
        Bucket: 'contents.devoret',
        Key: this.RESTAURANT_ID + '/' + target_directory,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType
      };
      bucket.upload(params, function (err, data) {
        if (err) {
          reject({status: false, message: 'There was an error uploading your file'});
        }
        resolve({status: true, data: data, message: 'Successfully uploaded file.'});
      });
    });

//for upload progress
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
     console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
     }).send(function (err, data) {
     if (err) {
     console.log('There was an error uploading your file: ', err);
     return false;
     }
     console.log('Successfully uploaded file.', data);
     return true;
     });*/
  }

  sweetAlert(
    title: string = 'are you sure?',
    message: string = 'Something went wrong!',
    type?: ('success' | 'error' | 'warning' | 'info' | 'question'),
    cancelbtn?: boolean,
    isToster?: boolean,
    where?: ('top' | 'top-start' | 'top-end' | 'center' | 'center-start' | 'center-end' | 'bottom' | 'bottom-start' | 'bottom-end'),
    duration?: number,
    confirmbtn?: boolean,
    closebtn?: boolean
  ) {
    return new Promise<Boolean>((resolve, reject) => {
      this.swalMixin.fire({
        title: title,
        text: message,
        icon: type ? type : 'question',
        showCancelButton: cancelbtn,
        toast: isToster,
        position: where,
        timer: duration ? duration : 10000,
        showConfirmButton: confirmbtn ? confirmbtn : false,
        showCloseButton: closebtn ? closebtn : false,
        confirmButtonText: '<i class="fa fa-check-circle"></i> Confirm',
        cancelButtonText: '<i class="fa fa-times-circle"></i> Cancel',
        confirmButtonColor: '#17a2b8',
        cancelButtonColor: '#343a40',
      }).then((result) => {
        if (result.value && type === 'question') {
          this.swalMixin.fire({title: 'Successfully done', icon: 'success', toast: true, timer: 3000, position: 'top-end', showConfirmButton: false});
          resolve(true);
        }
        resolve(false);
      });
    });
  }

  sweetAlertDelete() {
    this.swalMixin.fire({
      title: 'are you sure?',
      text: 'you want to delete it?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      confirmButtonColor: '#17a2b8',
      cancelButtonColor: '#343a40',
    }).then((result) => {
      if (result.value) {
        this.sweetAlert('Deleted!', 'Your file has been deleted.', 'success', null, true, 'top-end', 3000)
      }
    })
  }

  showWarning(msg){
    Swal.fire(msg)
  }


  loadingStart(
    title: string = 'Please Wait...',
    msg?: string,
    icon?: 'success' | 'error' | 'warning' | 'info' | 'question',
    isCustomClass: boolean = true,
    onOpenIsLoading: boolean = true
  ) {
    if (!this.swalMixin.isVisible()) {
      this.swalMixin.fire({
        title: title,
        text: msg,
        icon: icon,
        customClass: isCustomClass ? { container: 'progress-swal' } : null,
        onOpen: () => {onOpenIsLoading ? Swal.showLoading() : Swal.hideLoading()}
      });
    }
  }

  loadingClose(timeOutVal: number = 0) {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        if (this.swalMixin.isVisible()) {
          this.swalMixin.close();
          resolve();
        }
      }, timeOutVal);
    })
  }

  dateRageConfig(customRanges?: boolean, minDate?: Date, maxDate?: Date, rangeInputFormat?: string, dateInputFormat?: string) {
    return {
      ranges: customRanges ? [
        {
          value: [new Date(), new Date()],
          label: 'Today'
        }, {
          value: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date(new Date().setDate(new Date().getDate() - 1))],
          label: 'Yesterday'
        }, {
          value: [new Date(new Date().setDate(new Date().getDate() - 6)), new Date()],
          label: 'Last 7 Days'
        }, {
          value: [new Date(new Date().setDate(new Date().getDate() - 29)), new Date()],
          label: 'Last 30 Days'
        }, {
          value: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
          label: 'This Month'
        }, {
          value: [new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)],
          label: 'Last Month'
        }
      ] : null,
      minDate: minDate ? minDate : new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1),
      maxDate: maxDate? maxDate : new Date(),
      rangeInputFormat : rangeInputFormat ? rangeInputFormat : 'DD MMMM YYYY',
      dateInputFormat: dateInputFormat ? dateInputFormat : 'DD MMMM YYYY',
      showWeekNumbers: false,
      showPreviousMonth: minDate ? false : true
    }
  }
}

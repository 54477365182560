import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {config} from '../config';
import {Router} from '@angular/router';
import {StoreService} from './store.service';
import {SharedService} from './shared.service';


@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: StoreService,
    private shared: SharedService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${config.API_URL}v1/systemUserLogin`, {email, password})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token && user.status === 'success') {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          sessionStorage.setItem('user', JSON.stringify(user.data));
          sessionStorage.setItem('token', user.token);
          this.currentUserSubject.next(user.data);
        }

        return user;
      }));
  }

  logout() {
    sessionStorage.clear() //clear all from sessionStorage, when logout 
    const user_data = {data: null, token: null, is_logged: false};
    this.store.setLoginData(user_data);
    this.currentUserSubject.next(null);
    this.router.navigate(['/']).then(()=> {
      window.location.reload();
    });
  }

  
}

export const SET_SELECTED_RESTAURANT = 'SET_SELECTED_RESTAURANT';

export function restaurantReducer(state: any = '', action: any) {
  switch (action.type) {
    case SET_SELECTED_RESTAURANT:
      return action.payload;
    default:
      return state;
  }
}

import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: any;
  tamplate_setting: any;
  
  constructor(
    private authService: AuthenticationService,
    public renderer: Renderer2
  ) {
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  ngOnInit() {
    this.tamplate_setting = JSON.parse(localStorage.getItem('tamplate_setting'));
    this.isCollapseMenu();
  }
  collapseMenu() {
    this.tamplate_setting.sidebar_collapse = this.tamplate_setting.sidebar_collapse ? false : true;
    if(window.innerWidth > 1024) {
      localStorage.setItem('tamplate_setting', JSON.stringify(this.tamplate_setting));
      this.isCollapseMenu();
    } else {
        document.querySelector("body").classList.remove('sidebar-collapse');
        document.querySelector("body").classList.toggle('sidebar-open');
    }
  }
  isCollapseMenu() {
    this.tamplate_setting.sidebar_collapse ? this.renderer.addClass(document.body, 'sidebar-collapse') : this.renderer.removeClass(document.body, 'sidebar-collapse')
  }

  logout() {
    this.authService.logout();
  }


}

export const SET_SCHEDULE = 'SET_SCHEDULE';

export function scheduleReducer(state: any = {}, action: any) {
  switch (action.type) {
    case SET_SCHEDULE:
      return action.payload;
    default:
      return state;
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './services/auth.guard';
import { LoginComponent } from './layouts/login/login.component';
import { MainComponent } from './layouts/main/main.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'login' },
  { path: '', component: LoginComponent },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'admin/dashboard',
        loadChildren: 'src/app/pages/adminPanel/admin-dashboard/admin-dashboard.module#AdminDashboardModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'admin/restaurants', 
        loadChildren: 'src/app/pages/adminPanel/restaurants/restaurants.module#RestaurantsModule', 
        canActivate: [AuthGuard] 
      },
      {
        path: 'admin/new-restaurant', 
        loadChildren: 'src/app/pages/adminPanel/new-restaurant/new-restaurant.module#NewRestaurantModule', 
        canActivate: [AuthGuard] 
      },
      {
        path: 'admin/order-monitoring', 
        loadChildren: 'src/app/pages/adminPanel/admin-order-monitoring/admin-order-monitoring.module#AdminOrderMonitoringModule', 
        canActivate: [AuthGuard] 
      },
      {
        path: 'admin/reservation', 
        loadChildren: 'src/app/pages/adminPanel/admin-reservation-monitoring/admin-reservation-monitoring.module#AdminReservationMonitoringModule', 
        canActivate: [AuthGuard] 
      },
      {
        path: 'admin/user-tracking', 
        loadChildren: 'src/app/pages/adminPanel/user/admin-user-monitoring/admin-user-monitoring.module#AdminUserMonitoringModule', 
        canActivate: [AuthGuard] 
      },
      {
        path: 'admin/user-blocklist', 
        loadChildren: 'src/app/pages/adminPanel/user/admin-user-blocklist/admin-user-blocklist.module#AdminUserBlocklistModule', 
        canActivate: [AuthGuard] 
      },
      {
        path: 'admin/billing/sales-report', 
        loadChildren: 'src/app/pages/adminPanel/billing/salles-report/salles-report.module#SallesReportModule', 
        canActivate: [AuthGuard] 
      },
      {
        path: 'admin/billing/restaurant-report-details', 
        loadChildren: 'src/app/pages/adminPanel/billing/restaurant-report-details/restaurant-report-details.module#RestaurantReportDetailsModule', 
        canActivate: [AuthGuard]
      },
      {
        path: 'admin/billing/transaction', 
        loadChildren: 'src/app/pages/adminPanel/billing/transaction/transaction.module#TransactionModule', 
        canActivate: [AuthGuard] 
      },
      {
        path: 'admin/billing/transaction-summary', 
        loadChildren: 'src/app/pages/adminPanel/billing/transaction-summary/transaction-summary.module#TransactionSummaryModule', 
        canActivate: [AuthGuard] 
      },
      {
        path: 'admin/admin-system-logs', 
        loadChildren: 'src/app/pages/adminPanel/system-logs/system-logs.module#SystemLogsModule', 
        canActivate: [AuthGuard]
      },

      //restaurant panel start
      {
        path: 'restaurant/dashboard', 
        loadChildren: 'src/app/pages/restaurantPanel/dashboard/dashboard.module#DashboardModule', 
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/monitoring/order', 
        loadChildren: 'src/app/pages/restaurantPanel/order-monitoring/order-monitoring.module#OrderMonitoringModule', 
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/monitoring/reservation', 
        loadChildren: 'src/app/pages/restaurantPanel/reservation-monitoring/reservation-monitoring.module#ReservationMonitoringModule', 
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/monitoring/review', 
        loadChildren: 'src/app/pages/restaurantPanel/review-monitoring/review-monitoring.module#ReviewMonitoringModule', 
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/integration/crm', 
        loadChildren: 'src/app/pages/restaurantPanel/integration/crm-integration/crm-integration.module#CrmIntegrationModule', 
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/integration/payment', 
        loadChildren: 'src/app/pages/restaurantPanel/integration/payment-integration/payment-integration.module#PaymentIntegrationModule', 
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/integration/web', 
        loadChildren: 'src/app/pages/restaurantPanel/integration/web-integration/web-integration.module#WebIntegrationModule', 
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/integration/social-media', 
        loadChildren: 'src/app/pages/restaurantPanel/integration/social-media-integration/social-media-integration.module#SocialMediaIntegrationModule', 
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/menu-setup/cuisine',
        loadChildren: 'src/app/pages/restaurantPanel/menu-setup/cuisine/cuisine.module#CuisineModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/menu-setup/course',
        loadChildren: 'src/app/pages/restaurantPanel/menu-setup/course/course.module#CourseModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/menu-setup/category',
        loadChildren: 'src/app/pages/restaurantPanel/menu-setup/category/category.module#CategoryModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/menu-setup/grp-dish',
        loadChildren: 'src/app/pages/restaurantPanel/menu-setup/grp-dish/grp-dish.module#GrpDishModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/menu-setup/dish',
        loadChildren: 'src/app/pages/restaurantPanel/menu-setup/dish/dish.module#DishModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/menu-setup/pizza-dish',
        loadChildren: 'src/app/pages/restaurantPanel/menu-setup/pizza-dish/pizza-dish.module#PizzaDishModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/menu-setup/ala-carta',
        loadChildren: 'src/app/pages/restaurantPanel/menu-setup/ala-carta/ala-carta.module#AlaCartaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/menu-setup/duplicate-menu',
        loadChildren: 'src/app/pages/restaurantPanel/menu-setup/duplicate-menu/duplicate-menu.module#DuplicateMenuModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/setting/cms',
        loadChildren: 'src/app/pages/restaurantPanel/cms/all-pages/all-pages.module#AllPagesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/setting/seo',
        loadChildren: 'src/app/pages/restaurantPanel/cms/seo/seo.module#SeoModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/setting/custom-css',
        loadChildren: 'src/app/pages/restaurantPanel/cms/custom-css/custom-css.module#CustomCssModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/setting/schedule',
        loadChildren: 'src/app/pages/restaurantPanel/schedule-management/schedule-management.module#ScheduleManagementModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/setting/new-schedule',
        loadChildren: 'src/app/pages/restaurantPanel/new-schedule-management/new-schedule-management.module#NewScheduleManagementModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/setting/delivery-zone',
        loadChildren: 'src/app/pages/restaurantPanel/delivery-area-management/delivery-area-management.module#DeliveryAreaManagementModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/setting/booking-setting',
        loadChildren: 'src/app/pages/restaurantPanel/restaurant-booking-setting/restaurant-booking-setting.module#RestaurantBookingSettingModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/setting/configurations',
        loadChildren: 'src/app/pages/restaurantPanel/restaurant-basic-information/restaurant-basic-information.module#RestaurantBasicInformationModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/setting/primary-configurations',
        loadChildren: 'src/app/pages/restaurantPanel/restaurant-basic-information/restaurant-basic-information.module#RestaurantBasicInformationModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/setting/printer-setting',
        loadChildren: 'src/app/pages/restaurantPanel/printer-setting/printer-setting.module#PrinterSettingModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/management/promotions-and-deals',
        loadChildren: 'src/app/pages/restaurantPanel/promotion-management/promotion-management.module#PromotionManagementModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/management/vouchers',
        loadChildren: 'src/app/pages/restaurantPanel/voucher/voucher.module#VoucherModule'
      },
      {
        path: 'restaurant/management/coupon',
        loadChildren: 'src/app/pages/restaurantPanel/coupon-management/coupon-management.module#CouponManagementModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/management/service-config',
        loadChildren: 'src/app/pages/restaurantPanel/restaurant-service-configuration/restaurant-service-configuration.module#RestaurantServiceConfigurationModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/management/event-management',
        loadChildren: 'src/app/pages/restaurantPanel/event-management/event-management.module#EventManagementModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/management/blog-management',
        loadChildren: 'src/app/pages/restaurantPanel/blog-management/blog-management.module#BlogManagementModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/customers',
        loadChildren: 'src/app/pages/restaurantPanel/customers/customers.module#CustomersModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/system-user',
        loadChildren: 'src/app/pages/restaurantPanel/system-users/system-users.module#SystemUsersModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant/app-version-control',
        loadChildren: 'src/app/pages/restaurantPanel/app-version-control/app-version-control.module#AppVersionControlModule',
        canActivate: [AuthGuard]
      },
      
    ]
  }
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import {config} from '../config';


@Injectable({
  providedIn: 'root'
})
export class RestaurantService {
  private RESTAURANT_ID;
  private token: any;

  constructor(private http: HttpClient) {
    this.RESTAURANT_ID = sessionStorage.getItem('restaurant_id');
    this.token = sessionStorage.getItem('token');
  }

  addNewRestaurant(newRes) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v2/auth/admin/configureNewRestaurant';
    return this.http.post(url, newRes, {headers: headers});
  }

  updateRestaurantConfigById(body: any, resID: any){
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v2/auth/admin/updateConfig?id=' + resID;
    return this.http.post<any>(url, body, {headers: headers});
  }

  // addNewService(newService: any) {
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
  //   const url = config.API_URL + 'v1/auth/admin/createSubscription';
  //   return this.http.post(url, newService, {headers: headers});
  // }

  getAllSubscription(resID: any){
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/getSubscriptions?restaurant_id=' + resID;
    return this.http.get(url, {headers: headers});
  }
  updateSubscription(data: any, id: any){
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/updateSubscription?id=' + id;
    return this.http.put(url, data, {headers: headers});
  }
  deleteSubscription(id: any){
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/deleteSubscription?id=' + id;
    return this.http.delete(url, {headers: headers});
  }
  


  fetchRestConfigList(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v2/auth/admin/restaurantConfig';
    return this.http.get<any>(url, {headers: headers});
  }

  removeRestaurant(id: string) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    let params = new HttpParams();
    params = params.set('_id', id);
    const url = config.API_URL + 'v1/auth/admin/removeRestaurant';
    return this.http.delete(url, {headers: headers, params: params});
  }

  getRestaurantList() {
    let params = new HttpParams();
    const url = config.API_URL + 'v1/admin/__getAll';
    return this.http.get<any>(url, {params: params});
  }

  getPainationRestaurantList(pageId?: any, perPage?: any) {
    let params = new HttpParams();
    if (pageId) {
      params = params.set('pageNumber', pageId);
    }
    if (perPage) {
      params = params.set('nPerPage', perPage);
    }
    const url = config.API_URL + 'v1/admin/restaurantListWithPagination';
    return this.http.get<any>(url, {params: params});
  }

  getOneRestaurant(restaurantId) {
    const url = config.API_URL + 'v1/admin/__getOne/' + restaurantId;
    return this.http.get(url);
  }

  resDataSyncByResId(resId) {
    const restaurant = JSON.parse(sessionStorage.getItem('restaurant_data'));
    const url = config.API_URL + 'v1/admin/redisCacheSync/' + resId;
    return this.http.get(url+'?hostname='+restaurant.domain);
  }

  getBasicSetting() {
    const url = config.API_URL + 'v1/admin/basic/' + this.RESTAURANT_ID;
    return this.http.get(url);
  }

  getGeoLocation(postCode: string) {
    const url = config.API_URL + 'v1/admin/setGeoLocationFromGoogle?restaurant_id=' + this.RESTAURANT_ID + '&address=' + postCode;
    return this.http.get(url);
  }

  updateBasicSetting(data) {
    const restaurant = JSON.parse(sessionStorage.getItem('restaurant_data'));
    delete data._id;
    const url = config.API_URL + 'v1/admin/updateBasicInfo?hostname='+restaurant.domain;
    data.restaurant_id = this.RESTAURANT_ID;
    return this.http.put(url, data);
  }

  addSocialMedia(item) {
    const data = {'restaurant_id': this.RESTAURANT_ID, 'data': item}
    const url = config.API_URL + 'v1/admin/__createSocialMedia';
    return this.http.post(url, data);
  }

  deleteSocialMedia(id) {
    const data = {restaurant_id: this.RESTAURANT_ID, _id: id};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const reqx = new HttpRequest('POST', config.API_URL + 'v1/admin/__deleteSocialMedia', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }


  getSocialList() {
    const url = config.API_URL + 'v1/admin/getSocialMediaList/' + this.RESTAURANT_ID;
    return this.http.get(url);
  }

  getDeliveryArea() {
    const url = config.API_URL + 'v1/getDeliveryAreas/' + this.RESTAURANT_ID;
    return this.http.get(url);
  }

  addOffer(item) {
    const data = {restaurant_id: this.RESTAURANT_ID, data: item};
    const url = config.API_URL + 'v1/admin/addOffer';
    return this.http.put(url, data);
  }

  updateOffer(item) {
    const data = {restaurant_id: this.RESTAURANT_ID, offer_id: item._id, data: item};
    const url = config.API_URL + 'v1/admin/updateOfferData';
    return this.http.put(url, data);
  }

  deleteOffer(item) {
    const data = {restaurant_id: this.RESTAURANT_ID, offer_id: item};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const reqx = new HttpRequest('DELETE', config.API_URL + 'v1/admin/deleteOffer', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }

  addDiscount(item) {
    const data = {restaurant_id: this.RESTAURANT_ID, data: item};
    const url = config.API_URL + 'v1/admin/addDiscount';
    return this.http.put(url, data);
  }

  updateDiscount(item) {
    const data = {restaurant_id: this.RESTAURANT_ID, discount_id: item._id, data: item};
    const url = config.API_URL + 'v1/admin/updateDiscountData';
    return this.http.put(url, data);
  }

  deleteDiscount(item) {
    const data = {restaurant_id: this.RESTAURANT_ID, discount_id: item};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const reqx = new HttpRequest('DELETE', config.API_URL + 'v1/admin/deleteDiscount', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }

  addDeal(item) {
    item.restaurant_id = this.RESTAURANT_ID;
    const url = config.API_URL + 'v1/admin/createDeals';
    return this.http.post(url, item);
  }

  updateDeal(data) {
    const url = config.API_URL + 'v1/admin/updateBookingDeal';
    return this.http.put(url, data);
  }

  deleteDeal(item) {
    const data = {restaurant_id: this.RESTAURANT_ID, _id: item};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const reqx = new HttpRequest('DELETE', config.API_URL + 'v1/admin/deleteBookingDeal', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }


  getUserList(page) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/userList/' + this.RESTAURANT_ID + '/' + page;
    return this.http.get(url, {headers: headers});
  }

  addNewSystemUser(user) {
    user.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/addNewSystemUser';
    return this.http.post(url, user, {headers: headers});
  }

  getSystemUserList() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/getSystemUserList/' + this.RESTAURANT_ID;
    return this.http.get(url, {headers: headers});
  }

  changeSystemUserStatus(userId: string, status: boolean) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    let params = new HttpParams();
    params = params.set('uid', userId);
    params = params.set('status', status.toString());
    const url = config.API_URL + 'v1/auth/disableSystemUser';
    return this.http.get(url, {headers: headers, params: params});
  }

  updateSystemUser(data: any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/updateSystemUser';
    return this.http.put(url, data, {headers: headers});
  }

  removeSystemUser(userId: string) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    let params = new HttpParams();
    params = params.set('_id', userId);
    const url = config.API_URL + 'v1/auth/removeSystemUser';
    return this.http.delete(url, {headers: headers, params: params});
  }




  getRestaurantReviews() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/admin/restaurantReviews/' + this.RESTAURANT_ID;
    return this.http.get(url, {headers: headers});
  }

  deleteRestaurantReviews(item) {
    const data = {_id: item};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const reqx = new HttpRequest('DELETE', config.API_URL + 'v1/admin/removeReviewById', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }

  getbookingSetting() {
    let params = new HttpParams();
    params = params.set('restaurant_id', this.RESTAURANT_ID);
    const url = config.API_URL + 'v1/admin/admin_getBookingInfo';
    return this.http.get(url, {params: params});
  }

  addBookingSetting(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/admin/addBookingManagementInfo';
    return this.http.post(url, data, {headers: headers});
  }

  updateBookingSetting(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/admin/updateBookingSetting';
    return this.http.put(url, data, {headers: headers});
  }

  deleteBookingSetting(item) {
    const data = {_id: item};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const reqx = new HttpRequest('DELETE', config.API_URL + 'v1/admin/removeItemBookingSetting', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }

  getCouponListData() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/getAllPromos?restaurant_id=' + this.RESTAURANT_ID;
    return this.http.get<any>(url, {headers: headers});
  }

  addCoupon(item) {
    const data = {...item, restaurant_id: this.RESTAURANT_ID};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/createPromoCode';
    return this.http.post(url, data, {headers: headers});
  }

  updateCoupon(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/updatePromoById';
    return this.http.put(url, data, {headers: headers});
  }

  deleteCoupon(couponId: string) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/removePromosById';
    let params = new HttpParams();
    params = params.set('_id', couponId);
    return this.http.delete(url, {headers: headers, params: params});
  }

  getVersionInfo() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/getVersionInfo?restaurant_id=' + this.RESTAURANT_ID;
    return this.http.get<any>(url, {headers: headers});
  }

  createVersionInfo(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/createAppVersion';
    return this.http.post<any>(url, data, {headers: headers});
  }

  updateVersionInfo(body, paramID) {
    body.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/updateAppVersionControl?_id=' + paramID;
    return this.http.put<any>(url, body, {headers: headers});
  }

}

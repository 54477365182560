import { inititalUserState } from '../states/user.state';
export const SAVE_USER = 'SAVE_USER';

export function userReducer(state: any = inititalUserState, action: any) {
  switch (action.type) {
    case SAVE_USER:
      return action.payload;
    default:
      return state;
  }
}
